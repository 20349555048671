import React, { useState } from "react";
import {
    Videoueberwachung,
    Alarmanlage,
    Tuerschloss,
} from "./PriceCalculator/elements/Constants";

const Slider = ({ product }) => {
    const getWidth = () => document.getElementById("scrollBox").offsetWidth;

    const [state, setState] = useState({
        activeIndex: 0,
    });

    let products = {
        alarm: [
            {
                title: "Jablotron Bedienteil",
                description:
                    "Ihre Kontroll-Einheit für die gesamte Alarmanlage! Wenn Sie gerade nicht Ihr Smartphone zur Hand haben, können Sie die Alarmanlage komplett über das bidirektional kommunizierende Funk-Zugangsmodul von Jablotron steuern. Das Bedienteil enthält einen LCD Display über welchen Fehlermeldungen oder Informationen angezeigt werden sowie eine Tastatur zur Eingabe und ein RFID-Lesegerät für die einfache Bedienung der Alarmanlage.",
                image: "/img/products/alarm/jablotron-ja-100-bedienelement.png",
                alt: "Jablotron Bedienteil",
            },
            {
                title: "Jablotron Bewegungsmelder",
                description:
                    "Sichern Sie Ihre Innenräume und detektieren Sie alle Bewegungen! Dieser Bewegungsmelder ist speziell für den Innenraumschutz entwickelt worden. Er erkennt menschliche Bewegungen mit hoher Zuverlässigkeit und absoluter Präzision. Auch eine zusätzliche Fotoaufnahme bei Detektion ist möglich. Mit diesem Alarmsystem gelingt ein professioneller Einbruchschutz.",
                image: "/img/products/alarm/ja-150p_maxi.jpg",
                alt: "Jablotron Bewegungsmelder",
            },
            {
                title: "Daitem Bedienteil",
                description:
                    "Entdecken Sie eine völlig neue Ebene von Komfort und Sicherheit! Bedienen Sie nicht nur Ihre Alarmanlage, sondern auch Eingangstor, Beleuchtung, Pforte und Co. über ein zentrales Element! Ein Funk-Zugangsmodul vom Hersteller Daitem überzeugt nicht nur mit den herkömmlichen Funktionen wie einer einfachen Bedienung über Display oder Tasten, sondern auch mit Zusatzfunktionen wie einer Sprachausgabe und der Verknüpfung mit weiteren Einrichtungen.",
                image: "/img/products/alarm/daitem-d18-bedienelement.png",
                alt: "Daitem Bedienteil",
            },
            {
                title: "Daitem Bewegungsmelder",
                description:
                    "Lassen Sie Unbefugten keine Chance, unentdeckt zu bleiben! Bewegungsmelder der Marke Daitem agieren über Passiv-Infrarot-Detektion. Sie decken einen besonders großen Bereich ab und sind mit besonders sensitiven Sensoren ausgestattet, wodurch keine relevante Bewegung unentdeckt bleibt. Das ist Alarmtechnik mit höchster Zuverlässigkeit. ",
                image: "/img/products/alarm/171_21_pers.jpg",
                alt: "Daitem Bewegungsmelder",
            },
        ],
        video: [
            {
                title: "KI Kamera",
                description:
                    "Überlassen Sie die Auswertung und Analyse von Gefahrensituationen komplett Ihren Kameras! Mit künstlicher Intelligenz ausgestattet, können sie Menschen und Fahrzeuge erkennen und identifizieren, sowie eine aktive Abschreckung einleiten. Durch modernste „full-color technology“ werden Aufnahmen selbst in größter Dunkelheit in kräftigen Farben möglich.",
                image: "/img/products/video/ki-kamera.png",
                alt: "KI Kamera",
            },
            {
                title: "Dome Kamera",
                description:
                    "Behalten Sie den Rundumblick und lassen Sie sich nichts entgehen! Dome Kameras von Dahua können sowohl im Innen- als auch im Außenbereich eingesetzt werden. Sie weisen einen Vandalismusschutz auf und sind wasserresistent. Somit sind sie in der Lage, hochwertige Bilder von verdächtigen Personen zu machen.",
                image: "/img/products/video/dome-kamera-dahua.png",
                alt: "Dome Kamera",
            },
            {
                title: "Bullet Kamera",
                description:
                    "Erhalten Sie klarste Bilder und erkennen Sie jedes Detail unter allen Umständen!  Die Bullet Kameras von Dahua bestechen durch eine großräumige Abdeckung und hervorragende Bildauflösung von bis zu 8 Megapixel. Die dynamische Linsen- und Zoomanpassung ermöglicht einen robusten Einsatz in allen Beleuchtungsbedingungen.",
                image: "/img/products/video/bullet-kamera-dahua-2.png",
                alt: "Bullet Kamera",
            },
            {
                title: "PTZ Kamera",
                description:
                    "Nehmen Sie auch kleine Details in großen Entfernungen auf! Große Gelände und lange Auffahrten machen keine Probleme. PTZ Kameras von Dahua sind auf Aufnahmen in mittlerer bis hoher Distanz spezialisiert. Durch einen hochauflösenden Zoom und optimierte Speicherung sorgen sie für hohe Videoqualität ohne Festplatte oder Cloud zu belasten.",
                image: "/img/products/video/ptz-kamera.png",
                alt: "PTZ Kamera",
            },
        ],
        schliessanlage: [
            {
                title: "Elektronische Schließanlage",
                description:
                    "Mit der digitalen Schließanlage wAppLoxx von ABUS lassen sich alle Türen, Tore, Pforten und Zugänge in Echtzeit verwalten und auf- oder zuschließen. Mittels integriertem Nutzermanagement können die Zugangsberechtigungen einzelner Personen unmittelbar angepasst werden. Somit wissen Sie immer, welcher Nutzer wann, welchen Raum betreten hat.",
                image: "/img/products/schliessanlage/Türschlos-removebg-preview.png",
                alt: "Elektronische Schließanlage",
            },
            {
                title: "RFID Zugangschips",
                description:
                    "Verabschieden Sie sich vom teuren und langwierigen Ersetzen von verlorenen Schlüsseln. Die intelligenten Chip-Schlüssel und Transponder können bei Verlust problemlos deaktiviert werden. Alle Änderungen von Zugangsberechtigungen können sogar an ganze Benutzergruppen sofort übertragen werden.",
                image: "/img/products/schliessanlage/Picture1-removebg-preview (2)1.png",
                alt: "Zugangschips",
            },
        ],
    };

    switch (product) {
        case Alarmanlage:
            products = products.alarm;
            break;

        case Videoueberwachung:
            products = products.video;
            break;

        case Tuerschloss:
            products = products.schliessanlage;
            break;

        default:
            products = products.alarm;
            break;
    }

    const { activeIndex } = state;

    const handleArrowClick = (right, width) => {
        if (right) {
            if (activeIndex === products.length - 1) {
                document.getElementById("scrollBox").scrollLeft -=
                    activeIndex * width;
            } else {
                document.getElementById("scrollBox").scrollLeft += width;
            }
        } else {
            if (activeIndex === 0) {
                document.getElementById("scrollBox").scrollLeft +=
                    (products.length - 1) * width;
            } else {
                document.getElementById("scrollBox").scrollLeft -= width;
            }
        }
    };

    const handleDotClick = (newIndex) => {
        let actualIndex = Math.round(
            document.getElementById("scrollBox").scrollLeft / getWidth()
        );
        let amountOfScroll = newIndex - actualIndex;
        if (amountOfScroll !== 0) {
            document.getElementById("scrollBox").scrollLeft +=
                amountOfScroll * getWidth();
        }
    };

    const listenScrollEvent = () => {
        let newActiveIndex = Math.round(
            document.getElementById("scrollBox").scrollLeft /
                document.getElementById("scrollBox").offsetWidth
        );
        if (newActiveIndex !== activeIndex) {
            setState({
                ...state,
                activeIndex: Math.round(
                    document.getElementById("scrollBox").scrollLeft /
                        document.getElementById("scrollBox").offsetWidth
                ),
            });
        }
    };

    return (
        <div className="slider has-text-centered">
            <div className="how-to2">
                <h2
                    className="title"
                    style={{
                        paddingTop: "2rem",
                    }}
                >
                    {/* Beispielsicherheitskonzept */}
                    Ihr neues Sicherheitssystem - modular aufgebaut
                </h2>
                <h3
                    style={{
                        paddingBottom: "2rem",
                    }}
                >
                    So könnte Ihre Alarmanlage für Haus, Wohnung oder Gewerbe
                    aussehen
                </h3>
            </div>
            <div className="columns is-vcentered m-0">
                <div className="column has-text-right arrow is-hidden-mobile">
                    <i
                        className=" is-hidden-touch fas is-size-2 fa-arrow-circle-left"
                        onClick={() => handleArrowClick(false, getWidth())}
                    />
                </div>
                <div
                    id="scrollBox"
                    onScroll={listenScrollEvent}
                    className="carousel column is-three-quarters"
                >
                    {products.map((slide, i) => (
                        <div
                            key={slide + i}
                            id={slide.title}
                            className="carousel__item"
                        >
                            <div className="columns is-vcentered carousel__content">
                                <div className="column level m-0">
                                    <div className="level-right m-0">
                                        <img
                                            src={slide.image}
                                            alt={slide.alt}
                                            className="carousel__image level-item"
                                        />
                                    </div>
                                </div>
                                <div className="column has-text-left-tablet carousel__text">
                                    <h2 className="title">{slide.title}</h2>
                                    <p>{slide.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="column has-text-left arrow is-hidden-mobile">
                    <i
                        className="is-hidden-touch fas is-size-2 fa-arrow-circle-right"
                        onClick={() => handleArrowClick(true, getWidth())}
                    />
                </div>
            </div>
            <div className="dots">
                {products.map((product, i) => (
                    <i
                        key={i}
                        className="fas fa-circle"
                        onClick={() => handleDotClick(i)}
                        style={{
                            marginRight: "5px",
                            cursor: "pointer",
                            color: i === activeIndex ? "#777f8e" : "#e3e8ee",
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Slider;
