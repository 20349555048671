import React from 'react';
import PriceCalculationButton from './PriceCalculator/PriceCalculationButton';
import { Videoueberwachung } from './PriceCalculator/elements/Constants';

const Video = ({city}) => {
    let citySlug = typeof city !== 'undefined' ? city.slug : '';
    return (
        <div className="section video">
            <h2 className="has-text-centered">Die Vorteile mit unserer Videoüberwachung</h2>
            <ul className="checkmark centered-content">
                <li>
                    <h3>Echtzeitüberwachung via App</h3>
                    <p>Unsere Videoanlagen liefern Ihnen Echtzeitbilder – ob auf professionellen Monitoren oder Ihrem Smartphone können Sie somit jede Bewegung live verfolgen.</p>
                </li>
                <li>
                    <h3>Gezielte Aufnahme von Ereignissen</h3>
                    <p>Lassen Sie Bewegungsereignisse gezielt aufnehmen – Jedes Ereignis wird auf Ihrer Festplatte abgespeichert & Sie werden per Push-Notification informiert.</p>
                </li>
                <li>
                    <h3>4K Ultra HD Spitzenqualität</h3>
                    <p>Ob tagsüber oder nachts – Unsere Videoanlagen liefern Ihnen Bilder in Spitzenqualität, sodass selbst die kleinsten und wichtigsten Details für Sie zu erkennen sind.</p>
                </li>
                <li>
                    <h3>Professionelle Installation</h3>
                    <p>Unsere zertifizierten Sicherheitstechniker installieren die Videoanlagen fachmännisch & datenschutzkonform – damit Sie auch in dieser Hinsicht auf der sicheren Seite sind.</p>
                </li>
            </ul>
            <div className="has-text-centered">
                <PriceCalculationButton text='mehr erfahren' city={citySlug} product={Videoueberwachung}/><br/>
            </div>
        </div>
    );
};


export default Video;
